@import '../../../styles/mixins';
@import '../../../styles/variables';

.rps {
  padding: 0;
  background: $black-dark;

  &::after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: url('https://static.chai.finance/event/rps/rock@2x.png')
      url('https://static.chai.finance/event/rps/paper@2x.png')
      url('https://static.chai.finance/event/rps/scissors@2x.png')
      url('https://static.chai.finance/event/rps/lightRotate01@2x.png')
      url('https://static.chai.finance/event/rps/lightRotate02@2x.png')
      url('https://static.chai.finance/event/rps/new/lightOn.png')
      url('https://static.chai.finance/event/rps/new/lightOff.png')
      url('https://static.chai.finance/event/rps/homeSticker00@2x.png')
      url('https://static.chai.finance/event/rps/homeSticker01@2x.png')
      url('https://static.chai.finance/event/rps/homeSticker02@2x.png')
      url('https://static.chai.finance/event/rps/homeSticker03@2x.png')
      url('https://static.chai.finance/event/rps/homeSticker04@2x.png')
      url('https://static.chai.finance/event/rps/homeSticker05@2x.png');
  }
}
.rpsInvite {
  padding: 0;
  background: $black-dark;

  .homeSectionWrap {
    padding-top: 80px;
    background-position-y: 80px;
  }
}

.homeSectionWrap {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  width: 100%;
  min-height: 100%;
  background: $black-dark;

  .homeEvent {
    @include flex(flex-start);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    padding-left: 32px;
    background: #2a2d5e;

    .right {
      margin-left: 16px;

      p {
        font-size: 12px;
        letter-spacing: -0.5px;
        color: $white;
      }
    }
  }

  .homeSection {
    @include flex(flex-start);
    flex-direction: column;
    flex: 1;
    padding: 24px 0;
    overflow: hidden;

    h1 {
      position: relative;
      margin-bottom: 18px;
      font-size: 0;
      text-align: center;
    }

    h2 {
      position: relative;
      margin-bottom: 12px;
      font-size: 24px;
      letter-spacing: -1px;
      text-align: center;
      color: $white;
    }

    h3 {
      position: relative;
      font-size: 17px;
      font-weight: normal;
      letter-spacing: -0.5px;
      text-align: center;
      color: $white;
    }

    .ticket {
      @include flex(flex-start);
      width: 80px;
      height: 32px;
      padding-left: 16px;
      border-radius: 16px;
      border: solid 2px #3d3d3d;
      background-color: #141414;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: -0.5px;
      color: $white;
      transform: translateX(-16px);
    }

    .stickersWrap {
      width: 100%;
      height: 296px;
      max-width: 375px;
      margin: 32px auto 0;
      font-size: 0;

      .stickers {
        height: 296px;
        animation-duration: 1.8s;
        animation-name: showSticker;
        animation-iteration-count: infinite;
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }
  }

  .endSection {
    position: relative;
    padding-top: 216px;

    .endBg {
      position: absolute;
      top: 184px;
      width: 374px;
      height: 296px;
      opacity: 0.1;
    }

    .toggle {
      position: relative;
      margin-top: 24px;
    }
  }

  .heart01 {
    position: absolute;
    top: 8px;
    left: 50%;
    width: 64px;
    height: 64px;
    transform: translateX(-8px);

    & > * {
      position: absolute;
    }

    div {
      @include flex();
      color: #ffffff;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }

  .heart02 {
    position: absolute;
    top: 8px;
    left: 50%;
    width: 64px;
    height: 64px;
    transform: translateX(-8px);
  }

  .inviteeHeart {
    transform: translate(-8px, 80px);
  }

  .animation {
    animation-duration: 1.7s;
    animation-name: moveHeart;
    animation-delay: 400ms;
    animation-timing-function: linear;
  }
}

.endSectionWrap {
  background: $black-dark;
}

.bottomSection {
  margin-bottom: 66px;

  .blockButton {
    max-width: 375px;
    margin: 0 auto;
    padding: 0 32px;
  }

  button.primary {
    width: 100%;
    height: 74px;
    border: 4px solid $white;
    border-radius: 37px;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: -0.61px;
    color: $black;
    background: $white;
    box-shadow: 0 0 20px -1px rgba(255, 255, 255, 0.5), inset 0 -8px 8px 0 rgba(0, 0, 0, 0.3);
  }

  button.secondary {
    width: 100%;
    height: 74px;
    border: 4px solid $dark;
    border-radius: 37px;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: -0.61px;
    color: $black;
    background: $dark;
    box-shadow: inset 0 -8px 8px 0 rgba(0, 0, 0, 0.3);
  }

  a {
    display: inline-block;
    width: 100%;
    margin: 24px auto 0;
    font-size: 12px;
    letter-spacing: -0.5px;
    text-align: center;
    color: $gray;

    svg {
      margin-left: 4px;
      vertical-align: text-top;
    }
  }
}

.statisticSection {
  padding: 64px 24px;
  border-top: 8px solid $bg;

  & > * {
    max-width: 375px;
    margin: 0 auto;
  }

  h3 {
    margin-bottom: 24px;
    font-size: 24px;
    letter-spacing: -1px;
    text-align: center;
    color: $red;

    svg {
      vertical-align: middle;
    }
  }

  .statistic {
    @include flex();
    flex-direction: column;
    height: 106px;
    margin-top: 16px;
    border-radius: 16px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);

    p {
      font-size: 17px;
      letter-spacing: -0.5px;
      color: $black-lightest;
    }
    .countWrap {
      font-size: 26px;
      font-weight: bold;
      color: $black;

      .count {
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 0;
        color: $black;
        vertical-align: middle;
      }
      .unit {
        vertical-align: middle;
      }
    }
  }
}

.noticeSectionWrap {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  width: 100%;
  min-height: 100%;

  .noticeSection {
    padding: 24px;
    background: $black-dark;

    & > * {
      max-width: 375px;
      margin: 0 auto;
    }

    h4 {
      margin-top: 32px;
      margin-bottom: 24px;
      font-size: 17px;
      letter-spacing: -0.5px;
      color: $gray;
    }

    ul {
      padding-left: 14px;

      li {
        list-style: disc;
        margin: 16px 0;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: -0.5px;
        color: $dark;
      }
    }

    code {
      color: $white;
    }
  }
}

.roundSection {
  @include flex();
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 335px;
  height: 100%;
  margin: 0 auto;
}

.counterSection {
  padding-top: 48px;
  text-align: center;

  .counterNotice {
    @include flex(space-between);
    margin-bottom: 5px;

    .round {
      margin-left: 20px;
    }

    .coupon {
      margin-right: 12px;
    }
  }

  .count {
    font-size: 24px;
    color: $white;
  }

  p {
    margin-top: -18px;
    font-size: 17px;
    letter-spacing: -0.5px;
    color: $white;
  }
}

.gameSection {
  position: relative;
  max-width: 335px;
  margin: 16px auto;
  padding: 12px;
  border: 4px solid $black;
  border-radius: 16px;

  .rollingWrap {
    position: relative;
    height: 407px;
    font-size: 100px;
    text-align: center;
    background: url('https://static.chai.finance/event/rps/chai@2x.png') no-repeat;
    background-size: 100%;
  }

  .rollingBg {
    width: 279px;
    height: 279px;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
    animation-duration: 0.3s;
    animation-name: rollingBg;
    animation-iteration-count: infinite;
  }
  .rollingBg2 {
    animation-duration: 0.5s;
    animation-name: rollingBg2;
  }

  .rolling {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 279px;
    height: 279px;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .rollingAnimation {
    animation-duration: 0.5s;
    animation-name: rollingRps;
    animation-iteration-count: infinite;
    animation-play-state: paused;
  }
  .rollingAnimation2 {
    animation-duration: 1s;
    animation-name: rollingRps;
    animation-iteration-count: infinite;
    animation-play-state: paused;
  }
  .rollingStart {
    animation-play-state: running;
  }

  .rollingScissors {
    background-image: url('https://static.chai.finance/event/rps/scissors@2x.png');
  }
  .rollingPaper {
    background-image: url('https://static.chai.finance/event/rps/paper@2x.png');
  }
  .rollingRock {
    background-image: url('https://static.chai.finance/event/rps/rock@2x.png');
  }

  .play {
    @include flex();
    position: absolute;
    left: -4px;
    right: -4px;
    bottom: -4px;
    z-index: 9;
    height: 112px;
    padding: 12px;
    border-radius: 16px;
    background: #2c2c2c;
    box-shadow: 4px 4px 16px 0 rgba(0, 0, 0, 0.5);
  }

  .playDisabled {
    .rpsSecondary img {
      opacity: 0.3;
    }
  }

  .popup {
    @include flex();
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 156px;
    z-index: 9;
    height: 128px;
    border-radius: 24px;
    background: $bg;
    box-shadow: 0px 6px 6px 0 rgba(0, 0, 0, 0.1);

    .popupText {
      margin-bottom: 6px;
      font-size: 17px;
      font-weight: bold;
      letter-spacing: -0.5px;
      line-height: 21px;
      color: $black;
      text-align: center;
    }
    .popupText2 {
      font-size: 12px;
      letter-spacing: -0.5px;
      color: $red;
      text-align: center;
    }
    .popupArrow {
      position: absolute;
      bottom: -45px;
      animation-duration: 0.5s;
      animation-name: slideUpDown;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
  }

  .rpsPrimary {
    flex: 1 1 auto;
    height: 72px;
    margin: 0 6px;
    border: 2px solid #b30000;
    border-radius: 13px;
    color: $white;
    background: #e90000;
    background: linear-gradient(to bottom, #e90000 0%, #a00000 100%, #fa5353 100%);
    box-shadow: 2px 2px 8px 0 rgba(184, 0, 0, 0.25), -2px -2px 8px 0 rgba(184, 0, 0, 0.25),
      inset 4px 4px 8px 0 rgba(0, 0, 0, 0.5);
  }

  .rpsSecondary {
    flex: 1 1 auto;
    height: 72px;
    margin: 0 6px;
    border: 2px solid $black;
    border-radius: 13px;
    color: $white;
    background: #d6d6d6;
    background: linear-gradient(to bottom, #acacac 0%, #d6d6d6 100%);
    box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.25), inset 0px 4px 4px 0 rgba(255, 255, 255, 0.8);
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background: rgba(0, 0, 0, 0.6);
}

.resultWrap {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;

  .result {
    flex: 1;
    min-height: 400px;

    .animation {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.5;
    }
    .winAnimation {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .resultImage {
      position: absolute;
      top: 200px;
      left: 0;
      right: 0;
      max-width: 375px;
      margin: 0 auto;
    }
    .resultText {
      position: absolute;
      top: 295px;
      left: 0;
      right: 0;
      z-index: 5;
      font-size: 17px;
      font-weight: bold;
      letter-spacing: -0.5px;
      text-align: center;
      color: $white;
    }
    .winResultText {
      top: 310px;
      line-height: 28px;
    }

    .winTimer {
      @include flex();
      position: absolute;
      top: 380px;
      left: 50%;
      transform: translateX(-50%);
      margin: 0 auto;
      height: 26px;
      padding: 0 12px 0 8px;
      border-radius: 21.5px;
      box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px $dark;

      p {
        font-size: 12px;
        letter-spacing: -0.5px;
        text-align: center;
        color: $dark;
      }
    }

    .winCoupon {
      position: absolute;
      top: 130px;
      left: 0;
      right: 0;
      margin: 0 auto;
      visibility: hidden;
      animation-delay: 500ms;
      animation-duration: 500ms;
      animation-name: moveCoupon;
      animation-fill-mode: forwards;
    }

    .winLogo {
      position: absolute;
      top: 120px;
      left: 50%;
      transform: translateX(-50%);
      width: 372px;
    }
  }

  .bottomSection {
    z-index: 5;
    margin-bottom: 80px;

    button.primary {
      width: 100%;
      height: 74px;
      margin: 6px 0;
      border: 0;
      border-radius: 37px;
      font-size: 14px;
      letter-spacing: -0.5px;
      color: $white;
      background: #ef0000;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), inset 0 4px 4px 0 rgba(255, 255, 255, 0.3);
    }

    button.secondary {
      width: 100%;
      height: 74px;
      margin: 6px 0;
      border: 0;
      border-radius: 37px;
      font-size: 14px;
      letter-spacing: -0.5px;
      color: $white;
      background: $black-lighter;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), inset 0 4px 4px 0 rgba(255, 255, 255, 0.1);
    }

    .notice {
      margin-top: 10px;
    }
  }
}
.winWrap {
  background: $black-dark;
}

.modal {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50px;
  z-index: 9;
  margin: 0 8px;
  padding: 24px;
  border-radius: 24px;
  text-align: center;
  background: $white;

  &.showModal {
    animation-duration: 0.3s;
    animation-name: showModal;
    // animation-fill-mode: forwards;
  }
  &.hideModal {
    animation-duration: 0.3s;
    animation-name: hideModal;
    animation-fill-mode: forwards;
  }

  h3 {
    margin-top: 24px;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: -0.5px;
    color: $black;
  }

  p {
    margin-top: 8px;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.5px;
    color: $dark;
  }

  .ok {
    display: block;
    width: 240px;
    height: 60px;
    margin: 32px auto 24px;
    border-radius: 30px;
    font-size: 14px;
    letter-spacing: -0.5px;
    color: $white;
    background: $red;
  }

  .cancel {
    display: block;
    margin: 0 auto 10px;
    font-size: 14px;
    letter-spacing: -0.5px;
    text-decoration: underline;
    color: $gray;
  }
}

.notice {
  font-size: 12px;
  text-align: center;
  color: $dark;
}

.toastWrap {
  display: flex;
  align-items: center;

  & > span {
    width: 32px;
    height: 32px;
  }

  & > p {
    margin-left: 16px;
    font-size: 14px;
    letter-spacing: -0.5px;
    color: $white;
  }
}

@keyframes slideUpDown {
  from {
    margin-bottom: 0;
  }
  to {
    margin-bottom: 10px;
  }
}

@keyframes showSticker {
  0% {
    background-image: url('https://static.chai.finance/event/rps/homeSticker00@2x.png');
  }
  9% {
    background-image: url('https://static.chai.finance/event/rps/homeSticker00@2x.png');
  }
  10% {
    background-image: url('https://static.chai.finance/event/rps/homeSticker01@2x.png');
  }
  19% {
    background-image: url('https://static.chai.finance/event/rps/homeSticker01@2x.png');
  }
  20% {
    background-image: url('https://static.chai.finance/event/rps/homeSticker02@2x.png');
  }
  29% {
    background-image: url('https://static.chai.finance/event/rps/homeSticker02@2x.png');
  }
  30% {
    background-image: url('https://static.chai.finance/event/rps/homeSticker03@2x.png');
  }
  39% {
    background-image: url('https://static.chai.finance/event/rps/homeSticker03@2x.png');
  }
  40% {
    background-image: url('https://static.chai.finance/event/rps/homeSticker04@2x.png');
  }
  49% {
    background-image: url('https://static.chai.finance/event/rps/homeSticker04@2x.png');
  }
  50% {
    background-image: url('https://static.chai.finance/event/rps/homeSticker05@2x.png');
  }
  59% {
    background-image: url('https://static.chai.finance/event/rps/homeSticker05@2x.png');
  }
  60% {
    background-image: url('https://static.chai.finance/event/rps/homeSticker04@2x.png');
  }
  69% {
    background-image: url('https://static.chai.finance/event/rps/homeSticker04@2x.png');
  }
  70% {
    background-image: url('https://static.chai.finance/event/rps/homeSticker03@2x.png');
  }
  79% {
    background-image: url('https://static.chai.finance/event/rps/homeSticker03@2x.png');
  }
  80% {
    background-image: url('https://static.chai.finance/event/rps/homeSticker02@2x.png');
  }
  89% {
    background-image: url('https://static.chai.finance/event/rps/homeSticker02@2x.png');
  }
  90% {
    background-image: url('https://static.chai.finance/event/rps/homeSticker01@2x.png');
  }
  100% {
    background-image: url('https://static.chai.finance/event/rps/homeSticker01@2x.png');
  }
}

@keyframes moveHeart {
  0% {
    top: 8px;
    left: 50%;
  }
  15% {
    top: 20%;
    left: 68%;
  }
  30% {
    top: 40%;
    left: 75%;
  }
  45% {
    top: 60%;
    left: 65%;
  }
  60%,
  100% {
    width: 96px;
    height: 96px;
    top: calc(100% - 192px);
    left: 50%;
    transform: translateX(-50%);
  }
}

@keyframes showModal {
  from {
    bottom: -500px;
  }
  to {
    bottom: 50px;
  }
}
@keyframes hideModal {
  from {
    display: block;
    bottom: 50px;
  }
  to {
    display: none;
    bottom: -500px;
  }
}

@keyframes rollingBg {
  0% {
    background-image: url('https://static.chai.finance/event/rps/lightRotate01@2x.png');
  }
  49% {
    background-image: url('https://static.chai.finance/event/rps/lightRotate01@2x.png');
  }
  50% {
    background-image: url('https://static.chai.finance/event/rps/lightRotate02@2x.png');
  }
  100% {
    background-image: url('https://static.chai.finance/event/rps/lightRotate02@2x.png');
  }
}
@keyframes rollingBg2 {
  0% {
    background-image: url('https://static.chai.finance/event/rps/new/lightOn.png');
  }
  49% {
    background-image: url('https://static.chai.finance/event/rps/new/lightOn.png');
  }
  50% {
    background-image: url('https://static.chai.finance/event/rps/new/lightOff.png');
  }
  100% {
    background-image: url('https://static.chai.finance/event/rps/new/lightOff.png');
  }
}

@keyframes rollingRps {
  0% {
    background-image: url('https://static.chai.finance/event/rps/scissors@2x.png');
  }
  33% {
    background-image: url('https://static.chai.finance/event/rps/paper@2x.png');
  }
  66% {
    background-image: url('https://static.chai.finance/event/rps/rock@2x.png');
  }
  100% {
    background-image: url('https://static.chai.finance/event/rps/scissors@2x.png');
  }
}

@keyframes moveCoupon {
  from {
    visibility: visible;
    opacity: 0.5;
    top: 300px;
  }
  to {
    visibility: visible;
    opacity: 1;
    top: 130px;
  }
}
