@import '../../styles/mixins';
@import '../../styles/variables';

.card {
  border-radius: 8px;
  box-shadow: 0 13px 45px 0 rgba(0, 0, 0, 0.14);
  background: white;

  height: 188px;
  padding-top: 48px;

  line-height: 1.2;
  text-align: center;

  h1 {
    font-size: 22px;
    letter-spacing: -1px;
    margin-bottom: 5px;
  }

  p {
    font-weight: bold;
    letter-spacing: -2px;
  }
}

.details {
  margin-top: 32px;
  padding: 4px 24px;

  > h1 {
    display: none;
  }
}
