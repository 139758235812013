@import '../../styles/mixins';
@import '../../styles/variables';

.date + .date {
  margin-top: 46px;
}

.title {
  color: $dark;
  font-size: 14px;
  font-weight: normal;

  @include flex(flex-start);

  &:after {
    content: '';
    display: block;
    background: $light;
    margin-left: 12px;
    height: 1px;
    flex: 1;
  }
}
