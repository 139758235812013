@import '../styles/variables';

.birth {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0 auto;
  margin-top: -16px;

  .number {
    display: flex;
    justify-content: center;
    align-items: center;

    background: $bg;
    border-radius: 6px;
    font-size: 32px;

    flex: 1;
    height: 60px;
    margin: (5px / 2);
  }
}

.request {
  margin-top: 10px;
}
