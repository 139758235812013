@import '../../styles/mixins';
@import '../../styles/variables';

.desc {
  font-size: 14px;
  margin-bottom: 32px;
  margin-top: -16px;
}

.box {
  @include flex(flex-start);
  background: $bg;
  border-radius: 24px;
  margin-bottom: 12px;
  height: 88px;
  font-size: 17px;
  padding: 0 24px;
}

.circle {
  @include flex();
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  background: $gray;

  svg {
    fill: white;
  }
}
