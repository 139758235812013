@import '../../styles/mixins';
@import '../../styles/variables';

.tab {
  overflow-x: scroll;
  overflow-y: hidden;
  margin: -16px (-$gutter) 32px;

  &::-webkit-scrollbar {
    display: none;
  }

  .inner {
    white-space: nowrap;
  }

  .button {
    flex: none;
    padding: 0 26px;
    height: 40px;
    border-radius: 20px;
    background: $bg;
    margin-right: 6px;

    &:first-child {
      margin-left: $gutter;
    }

    &:last-child {
      margin-right: $gutter;
    }
  }

  .active {
    background: $black;
    color: white;
    font-weight: $sb;
  }
}

.html {
  word-break: initial;
}
