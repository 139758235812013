@import './variables';

@mixin flex($justify-content: center, $align-items: center) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin border() {
  content: '';
  display: block;
  background: $black;
  height: 2px;
  border-radius: 1px;
}
