@import '../styles/variables';

.SocialNumber {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;

  .head,
  .tail {
    flex: 1;

    input {
      width: 100%;
    }
  }

  .tail {
    display: flex;

    > * {
      flex: 1;
    }
  }

  .hyphen {
    flex: none;
    background: $light;
    margin: 0 16px;
    width: 12px;
    height: 2px;
  }

  .bullet {
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      content: '';
      display: block;
      background: $black;
      border-radius: 50%;
      width: 6px;
      height: 6px;
    }
  }
}
