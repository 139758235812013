.article {
  p + p {
    margin-top: 1em;
  }

  ul {
    margin-bottom: 1em;
  }

  dd {
    white-space: pre-line;
  }
}
