@import '../mixins';

/* 기본 */
.btn {
  border-radius: 4px;
  display: inline-block;
  font-weight: 500;
  padding: 20px;
  line-height: 20px;
  text-align: center;
}

/* 모양 */
.btn-block {
  display: block;
  width: 100%;
}

.btn-submit {
  margin-top: 20px;
}

/* 색상 */
.btn-primary {
  background: $red;
  color: $white;
  font-size: 17px;
  font-weight: bold;
  transition: background 200ms ease;

  &:disabled {
    background: $light;
  }

  &:active {
    background: #bb2b1b;
    transition: unset;
  }
}

.btn-disabled {
  background: $light;
  color: $white;
  font-size: 17px;
}

.btn-outline {
  border: 1px solid $black;
  border-radius: 4px;
  color: $black;
  font-size: 18px;

  &:disabled {
    border-color: $light;
    color: $light;
  }
}

.btn-outline-primary {
  border: 1px solid $red;
  border-radius: 4px;
  color: $red;
  font-size: 17px;

  &:disabled {
    border-color: $light;
    color: $light;
  }
}

.btn-text {
  @include flex();

  background: $white;
  color: $black;
  font-size: 14px;
  font-weight: 400;
  padding: 8px;

  svg {
    fill: $black;
  }

  &:disabled {
    color: $light;

    svg {
      fill: $light;
    }
  }
}

.btn-link {
  @include flex();

  background: $white;
  color: $red;
  font-size: 16px;
  font-weight: 500;
  padding: 8px;

  svg {
    fill: $red;
  }

  &:disabled {
    color: $light;

    svg {
      fill: $light;
    }
  }
}

.btn-footer {
  background: $black;
  color: $white;
  border-radius: 30px;
  height: 60px;
  font-size: 14px;
  font-weight: $eb;
  transition: background 200ms ease;

  &:disabled {
    background: $disabled;
  }

  &:active {
    background: $black-lightest;
    transition: unset;
  }
}
