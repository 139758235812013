@import '../styles/variables';

._stealien_keypad_border {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  max-width: 360px;
  width: 100%;

  position: absolute;
  bottom: 20px;
  left: 50% !important;
  transform: translate(-50%, 0);
}

._stealien_keybutton {
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 22px;
  height: 72px;
  width: (100% / 3);

  &:active {
    color: $gray;
  }
}

._stealien_keybutton_shift,
._stealien_keybutton_cancel,
._stealien_keybutton_ok {
  display: none;
}
