@import '../styles/mixins';
@import '../styles/variables';

/* 목록 */
.list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 15px;
  overflow-y: auto;
}

/* 항목 */
.item {
  font-weight: 400;
  padding: 15px $gutter;
  text-align: left;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .multiple & {
    @include flex();

    flex-direction: column;
    font-size: 14px;
    padding: 0;
    height: 110px;

    img {
      margin-bottom: 6px;
      width: 45px;
      height: 45px;
    }
  }

  &.active {
    color: $red;
    font-weight: bold;

    svg {
      fill: $red;
    }

    .multiple & {
      background: $bg;
      color: inherit;
    }
  }

  &.active2 {
    border-radius: 26px;
    font-weight: bold;
    color: $white;
    background-color: $black-dark;

    .multiple & {
      background: $bg;
      color: inherit;
    }
  }

  &.disabled {
    cursor: default;
    color: $gray;

    svg {
      fill: $gray;
    }
  }

  small {
    @include flex();
  }

  position: relative;
}

.overlay {
  display: flex;
  align-items: center;

  position: absolute;
  top: 50px;

  background-color: $gray;
  color: white;
  font-size: 9px;
  font-weight: bold;

  $height: 16px;
  height: $height;
  border-radius: ($height / 2);
  padding: 0 10px;
}
