/* Colors */
$red: #ef0000;
$bg: #f8f8f8;

// BW
$black: #141414;
$black-dark: #000000;
$black-lighter: #282828;
$black-lightest: #3d3d3d;
$white: #ffffff;

// Grayscales
$deepdark: #5b5b5b;
$dark: #8f8f8f;
$gray: #b2b2b2;
$light: #d6d6d6;
$disabled: #ebebeb;

/* Sizes */
$gutter: 24px;
$nav: 44px;
$nav-large: 64px;

/* Fonts */
$font-family: 'ChaiGothic', sans-serif;
$m: 500;
$sb: 600;
$eb: 800;
$h: 900;
