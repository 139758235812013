@import '../styles/mixins';
@import '../styles/variables';

.border {
  margin-top: 64px;
}

.item {
  padding: 32px 0;

  &:not(:first-child) {
    border-top: 1px solid fade-out($light, 0.5);
  }

  &:last-child {
    margin-bottom: 32px;
  }
}

.header {
  h1 {
    font-size: 24px;
    font-weight: $h;
    margin-top: 1px;
    margin-bottom: 2px;
  }
}

.bubble {
  width: 50px;
  height: 29px;
  position: relative;

  span {
    position: absolute;
    color: white;
    font-size: 12px;
    font-weight: $eb;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 3px;
    white-space: nowrap;
  }
}

.desc {
  color: $dark;
  white-space: pre-line;
}

.max {
  margin-left: 4px;
}
