@import '../../styles/variables';

.select {
  color: $red;
  border-bottom: 2px solid $red;

  svg {
    fill: $red;
    margin-left: 6px;
  }
}
