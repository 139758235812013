@import '../styles/variables';

.header {
  padding-top: 80px;
}

.title {
  font-size: 19px;
  font-weight: 700;
  letter-spacing: -1px;
  margin-bottom: $gutter;
  text-align: center;
}

.input {
  cursor: default;
  color: transparent;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
}
