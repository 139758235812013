@import '../styles/variables';

.InputGroup {
  display: flex;
  line-height: normal;
  padding-bottom: 4px;

  > input {
    width: 100%;
  }

  label + & {
    margin-top: 4px;
  }

  & + label {
    margin-top: $gutter;
  }

  // 밑줄 트랜지션
  border-bottom: 1px solid $light;
  position: relative;

  &:after {
    background: $red;
    content: '';
    height: 1px;

    position: absolute;
    bottom: -1px;
    left: 50%;
    width: 0%;
    transform: translate(-50%, 0);
    transition: width 200ms ease;
  }

  &.focus:after {
    width: 100%;
  }
}
