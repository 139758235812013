@import '../styles/variables';

.Disconnected {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  background: fade-out($black, 0.2);
  color: #fff;
  font-size: 12px;
  padding: 16px;
  text-align: center;
}
