@import '../styles/variables';

.backdrop {
  background: transparent;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.children {
  position: absolute;
  background: white;
  z-index: (9999 + 1);
  border-radius: 6px;
  border: 1px solid $light;
  box-shadow: 0 2px 6px 0 fade-out(black, 0.8);
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
}
