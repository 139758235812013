@import '../styles/variables';

.Toastify__toast {
  color: white;
  font-size: 14px;
  margin: 10px 20px;
  min-height: 56px;
  text-align: center;
  white-space: pre-line;
}

.Toastify__toast--default {
  background: fade-out($dark, 0.1);
  border-radius: 4px;
  font-weight: bold;
}

.Toastify__toast--error {
  background: fade-out($red, 0.2);
  border-radius: 8px;
}

.Toastify__toast--rps {
  margin: 0 24px 58px;
  padding: 20px 16px;
  border-radius: 16px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #3d3d3d;
}