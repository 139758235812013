@import '../styles/variables';

.Nav {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: $white;
  font-size: 17px;
  font-weight: 500;
  padding: 0 $gutter;
  height: $nav + 12px;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: (1000 - 1);

  transition: box-shadow 300ms ease;

  &.touched {
    box-shadow: 0 1px 0 0 fade-out(black, 0.9);
  }

  /* background */
  &.bg {
    background: $bg;
  }

  &.transparent {
    background: transparent;

    svg {
      fill: $black;
    }
  }
}

.desktop {
  border-bottom: 1px solid $light;
  padding-top: 0;
  height: $nav-large;
  position: relative;

  .chai {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 15px;
  }

  &.cancel {
    flex-direction: row-reverse;
  }
}

.title {
  flex: 1;
  font-size: inherit;
  font-weight: inherit;
  text-align: center;
}

.button {
  display: flex;
  align-items: center;

  height: $nav;
  margin: 0 -$gutter;
  padding: 0 18px;

  &:disabled svg {
    fill: $light;
  }
}

.buttonBack {
  padding: 0 24px;

  svg {
    margin-right: 8px;
  }
}
