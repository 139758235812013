@import '../../styles/mixins';
@import '../../styles/variables';

.bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $black-dark;
}

.center {
  @include flex();
  flex-direction: column;
  height: 100%;
  padding-bottom: 48px;
  z-index: 1;
}

.buttonWrapper {
  width: 240px;
  height: 60px;
  margin-top: 32px;
  border-radius: 30px;
  background-image: linear-gradient(to left, #ef0000 3%, #ff005c 97%);
  text-align: center;
  line-height: 60px;

  button {
    width: 100%;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: -0.5px;
    color: $white;
  }
}

.result {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.resultCenter {
  @include flex();
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  padding-bottom: 48px;
  z-index: 1;
  animation-name: fadein;
  animation-duration: 0.5s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.priceWrapper {
  margin-top: 16px;
  font-size: 32px;
  line-height: 37px;
  font-weight: bold;
  letter-spacing: -1px;
  text-align: center;
  color: $white;
}

.errorBg {
  background-image: url('./images/lucky_cashback_bg.png');
  background-size: cover;
}

.errorText {
  margin: 16px 30px 0;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: center;
  color: $white;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
