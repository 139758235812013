@import '../../styles/variables';

.container {
  padding: $gutter;
}

.header > h1 {
  font-size: 32px;
}

.bg {
  background: #f9f9f9;
}

.upper {
  background: white;
  padding-top: 6px;
}
