@import '../../../styles/mixins';
@import '../../../styles/variables';

.container {
  margin: -24px;
  //padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

img.main {
  //height: 528px;
  margin: auto;
  object-fit: contain;
  align-self: flex-start;
}

.btn_apply {
  margin: auto;
  align-items: center;
  object-fit: contain;
}

.btn_history {
  margin: auto;
  align-items: center;
  object-fit: contain;
}

.btn_info {
  margin: auto auto auto auto;
  align-items: center;
  object-fit: contain;
}

img.benefit {
  //height: 1080px;
  margin: auto;
  padding-top: -32px;
  object-fit: contain;
}

img.notice {
  //height: 1131px;
  margin: auto;
  object-fit: contain;
}

.modal {
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
}

.showModal {
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 344px;
  height: 229px;
  border-radius: 24px;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #f8f8f8;
  z-index: 9;

  .title {
    margin: 48px auto auto auto;
    width: 216px;
    height: 28px;
    font-family: RixSinGoPEB;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: -0.5px;
    text-align: center;
    color: #141414;
  }

  .description {
    margin: 8px auto auto auto;
    width: 311px;
    height: 21px;
    font-family: RixSinGoPM;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.5px;
    text-align: center;
    color: #8f8f8f;
  }

  .btn {
    margin: 32px auto auto auto;
    width: 240px;
    height: 60px;
    border-radius: 30px;
    background-color: #ef0000;

    span {
      padding-top: 22px;
      display: flex;
      justify-content: center;
      align-self: center;
      font-family: RixSinGoPEB;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.5px;
      color: #fff;
    }
  }
}
