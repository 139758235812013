@import '../styles/mixins';
@import '../styles/variables';

.tax {
  color: $red;
  font-size: 12px;
  font-weight: $eb;
  position: relative;
  width: 89px;
  height: 27px;
  margin-bottom: 9px;

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    padding-bottom: 2px;
  }
}

.content {
  left: 36px;
  right: 36px;
  padding: 0;
}

.brand {
  color: #3b3b3b;
  font-size: 29px;
  font-weight: $eb;
  letter-spacing: -1px;
  line-height: 1.24;

  span {
    color: $red;
  }
}

.app {
  @include flex(space-between);

  border-radius: 30px;
  font-size: 16px;
  font-weight: $eb;
  margin-top: 32px;

  svg {
    fill: white;
  }
}

.store {
  border-radius: 0;
  border-top: 2px solid $black;
  display: block;
  font-size: 16px;
  font-weight: 500;
  padding: 18px 0;
  text-align: left;

  p {
    color: $dark;
    font-size: 11px;
  }
}

.bubble {
  @include flex();
  position: absolute;
  top: -44px;
  left: 0;

  background: white;
  background-image: url('../images/Bubble.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  color: $red;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.3;
  padding-bottom: 8px;
  width: 132px;
  height: 58px;
}

.fadeUp {
  animation-name: fadeUp;
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 5%, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.spinner {
  display: flex;
  align-items: center;
}
