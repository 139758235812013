@import '../../styles/mixins';
@import '../../styles/variables';

.Coupon {
  display: flex;
  justify-content: space-between;

  border: 1px solid $light;
  font-size: 12px;
  margin-top: 10px;
  padding: $gutter;
}

.merchant {
  @include flex(flex-start);

  img {
    margin-right: 4px;
  }
}

.title {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -1px;
}

.download {
  border-left: 1px dashed $light;
  color: $red;
  font-size: 14px;
  padding-left: $gutter;

  svg {
    fill: $red;
  }
}

.muted {
  color: $dark; // .download 아래에서 선언할 것
}

.black {
  color: $black; // .download 아래에서 선언할 것
}
