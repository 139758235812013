@import '../../styles/mixins';
@import '../../styles/variables';

.h1 {
  line-height: 24px;
}

.padding {
  height: 24px;
}

.gray {
  color: $dark;
}

.desktop {
  padding: 24px 32px;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
  width: 24px;
  height: 24px;
}

.schemeTable {
  width: 100%;
  border-spacing: 0 4px;
  border-collapse: separate;

  thead tr {
    background-color: $dark;
  }

  thead th {
    color: white;
    text-align: right;
    line-height: 20px;
    padding: 8px;
  }

  thead th:first-child {
    padding-left: 44px;
    text-align: left;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  thead th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-right: 18px;
  }

  tbody tr {
    height: 56px;
    text-align: center;
    background-color: #f0f0f0;
  }

  tbody tr:first-child {
    background-color: #f8f8f8;
  }

  tbody tr:last-child {
    background-color: #d6d6d6;
  }

  tbody td {
    line-height: 24px;
    padding: 0 8px;
    text-align: right;
  }

  tbody td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding-left: 16px;
    text-align: left;
  }

  tbody td:last-child {
    font-family: Gotham-Bold;
    font-weight: bold;
    padding-right: 18px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  th,
  td {
    border: none;
  }
}

.schemeTableCashbackAmountMax {
  font-family: RixSinGoPM;
  color: $black-lightest;
}

.schemeTableProbability {
  font-family: RixSinGoPEB;
  color: $black-lightest;
}
