@import '../../styles/variables';

.message {
  color: $gray;
  text-align: center;
  padding-top: 50px;

  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
}

.more {
  color: $gray;
  display: block;
  text-align: center;
  font-size: 80%;
  padding: 10px;
  width: 100%;
}
