@import 'mixins';
@import 'variables';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
}

body {
  background: $white;
  color: $black;
  font-family: $font-family;
  font-size: 14px;
  font-weight: $m;
  line-height: 1.5;
  letter-spacing: -0.5px;
  margin: 0 auto;
  padding: 32px $gutter;
  user-select: none;
  word-break: keep-all;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.large {
  /* 데스크탑 레이아웃 */
  padding: 0;

  $breakpoint: 480px;
  $height: 640px;

  #root {
    @include flex(flex-start, stretch);
    flex-direction: column;
    height: $height;
    position: relative;

    > nav {
      flex: none;
    }

    > article {
      flex: 1;
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none !important;
      }
    }

    @media (min-width: ($breakpoint + 2 * $gutter)) {
      /* 넓은 데스크탑 레이아웃 */
      border: 1px solid $light;
      border-radius: 10px;

      width: $breakpoint;
      overflow: hidden;

      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -($breakpoint / 2);
      margin-top: -($height / 2);
    }
  }
}

h1,
h2,
h3,
p,
ul {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

dl {
  margin: 0;
}

dd {
  margin: 0;
}

pre {
  font-size: 14px;
  overflow: auto;
  white-space: pre-wrap;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
}

svg {
  fill: $black;
}

table {
  border-collapse: collapse;
}

th,
td {
  border: 1px solid $light;
}

button,
input,
select {
  border: 0;
  border-radius: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;

  &[type='submit'] {
    appearance: none;
  }
}

button {
  cursor: pointer;
}

select {
  appearance: none;
  cursor: pointer;
}

label {
  color: $dark;
  display: block;
  font-size: 12px;
  font-weight: 400;
}

::-ms-clear,
::-ms-expand {
  display: none;
}
