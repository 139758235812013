@import '../styles/mixins';
@import '../styles/variables';

$transition-duration: 200ms;

/* Modal */
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  transition: background $transition-duration ease;
  background: fade-out($black, 0.5);
  &.hidden {
    background: fade-out($black, 1);
  }
}

.content {
  background: $white;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  outline: 0;

  transition: transform $transition-duration ease;
  transform: translate(0, 0);
  .hidden & {
    transform: translate(0, 100%);
  }
}

/* Component */
.Drawer {
  display: flex;
  flex-direction: column; // 목록 영역에서 스크롤을 허용하기 위해
  max-height: 40vh;

  &.large {
    max-height: 70vh;
  }
}

.v2 {
  border-radius: 32px 32px 0 0;
  background-color: $black;

  header {
    border-bottom-color: $black-dark;

    h1 {
      padding-left: 32px;
      color: $white;
    }
    button {
      height: 56px;
      padding-right: 32px;
      color: $dark;
    }
  }

  ul {
    padding: 16px 8px;

    li {
      margin: 2px;

      button {
        padding: 13px 24px;
        font-size: 17px;
        color: $dark;
      }
    }
  }
}

/* 제목 */
.header {
  flex: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid $light;
}

.title {
  font-size: 14px;
  font-weight: 500;
  padding-left: $gutter;
}

.close {
  @include flex();

  height: 50px;
  padding: 0 $gutter;
}
