@import '../../styles/variables';

.item {
  font-size: 12px;
  margin-top: 16px;

  h1 {
    color: $dark;
    font-weight: 400;
    font-size: inherit;
  }

  p {
    white-space: pre-line;
  }
}

.compact {
  color: $dark;
  line-height: 1.5;
}
