@import '../styles/variables';

.Footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px $gutter;
}

.desktop {
  padding: 20px 0;
  position: absolute;
}
