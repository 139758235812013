.ribbon {
  position: fixed;
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  background: brown;
  color: white;
  font-weight: bold;
  padding: 5px 10px;
  text-align: center;
}
