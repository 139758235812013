.bridge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.bridge > div {
  padding: 0 32px;
  text-align: center;
}

.bridgeWrapper {
  padding-top: 20px;
}

.bridgeWrapper > h1 {
  font-family: RixSinGoPEB;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.5px;
  text-align: center;
  color: #141414;
  margin: 0;
  margin-bottom: 8px;
}

.bridgeWrapper > h2 {
  font-family: RixSinGoPM;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: -0.5px;
  text-align: center;
  color: #8f8f8f;
}
