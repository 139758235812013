@import '../styles/variables';

@mixin fixed() {
  position: fixed;
  left: 0;
  right: 0;
}

.ButtonGroup {
  @include fixed();
  top: 0;
  z-index: 1;

  display: flex;

  label {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: $nav;
    background: $white;
    border-bottom: 2px solid $gray;
    color: $gray;
  }

  .active {
    border-bottom: 2px solid $black;
    color: $black;
    font-weight: 700;
  }
}

.submit {
  @include fixed();
  bottom: 0;
}
