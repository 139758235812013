.wrapper {
  position: relative;
}

.button {
  display: flex;
  align-items: center;

  svg {
    margin-left: 4px;
  }
}
