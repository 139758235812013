@import '../../../styles/mixins';
@import '../../../styles/variables';

.container {
  margin: -24px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title_box {
  display: flex;
  flex-direction: row;
  font-family: RixSinGoPM;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.58px;
  color: #8f8f8f;
  align-items: baseline;
  width: 100%;
  .title {
    margin-right: auto;
    height: 27px;
    font-family: RixSinGoPH;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: #141414;
  }

  .question_mark {
    margin-left: auto;
  }
}

.round_on1 {
  width: 100%;
  height: 104px;
  margin-top: 24px;
  padding: 30px 24px 31px;
  border-radius: 24px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: row;
}

.round_on2 {
  width: 100%;
  height: 104px;
  margin-top: 12px;
  padding: 31px 24px 30px;
  border-radius: 24px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: row;
}

.left_area {
  margin: auto;
  flex-grow: 1;
  flex-basis: 50%;
}

.right_area {
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  /* margin-left: auto; */
  /* align-items: baseline; */
  justify-content: space-evenly;
  align-content: flex-end;

  div {
    display: flex;
    justify-content: flex-end;
  }

  .text1 {
    height: 16px;
    font-family: RixSinGoPM;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.58px;
    text-align: right;
    color: #8f8f8f;

    .exclamation_mark {
      margin-right: 2px;
    }
  }

  span {
    height: 23px;
    font-family: RixSinGoPH;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.83px;
    text-align: right;
    color: #141414;
  }
}

.my {
  margin: -24px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header {
  width: 100%;
  height: 56px;
  opacity: 100;
  background-color: #ffffff;
}

.header > svg {
  width: 7px;
  height: 12px;
  object-fit: contain;
  align-self: flex-start;
  margin: auto;
  margin-top: 22px;
}

.summary {
  width: 100%;
  margin-top: 32px;
  align-self: flex-start;
}

.mon {
  height: 16px;
}

.mon > span {
  font-family: RixSinGoPM;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #8f8f8f;
}

.current {
  width: 100%;
  margin-top: 15px;
  display: flex;
  align-items: flex-end;
}

.amount {
  height: 37px;
  flex: none;
}

.amount > span {
  font-family: RixSinGoPH;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.67px;
  color: #141414;
}

.onoff {
  margin-left: auto;
  flex: none;
}

.on {
  width: 112px;
  height: 24px;
  object-fit: contain;
}

.maxCashback {
  margin-top: 1.2vh;
  display: flex;
  align-items: center;
}

.maxCashback > span {
  margin-left: 1.3vw;
  font-family: RixSinGoPM;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #b2b2b2;
}

.banner {
  background-image: url('../../../images/img_banner_autoplus.png');
  background-size: 100%;
  object-fit: cover;
  background-repeat: no-repeat;

  width: 100%;
  margin-top: 5.3vh;
  height: 19.8vh;
}

.detail {
  margin-top: 34px;
  align-self: flex-start;
  width: 100%;
}

.detail_h {
  height: 16px;
  justify-content: center;
  margin-bottom: 32px;
}

.detail_h > Select {
  width: 80px;
  font-family: RixSinGoPEB;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #141414;
  margin-right: 7px;
}

.chevron_right {
  width: 7px;
  height: 12px;
  object-fit: contain;
  margin: auto;
}

.payment_1 {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.rectangle {
  width: 1px;
  height: 10px;
  margin-left: 4px;
  margin-right: 4px;
  background-color: #ebebeb;
}

.payment_day {
  height: 14px;
  font-family: RixSinGoPR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #b2b2b2;
}

.payment_status {
  height: 14px;
  font-family: RixSinGoPR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #b2b2b2;
}

.payment_2 {
  align-items: flex-end;
  display: flex;
  margin-bottom: 5px;
}

.payment_2_1 {
  flex: none;
}

.payment_2_1 > span {
  font-family: RixSinGoPM;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #3d3d3d;
}

.payment_2_2 {
  margin-left: auto;
  flex: none;
}

.payment_amount {
  font-family: RixSinGoPH;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  text-align: right;
  color: #141414;
}

.payment_amount_cancel {
  font-family: RixSinGoPH;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  text-align: right;
  color: #ebebeb;
}

.payment_amount_cancel_p {
  position: relative;
}

.payment_amount_cancel_line {
  height: 2px;
  background-color: #ebebeb;
  position: absolute;
  width: 100%;
  bottom: 50%;
}

.payment_cashback {
  font-family: RixSinGoPH;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  text-align: right;
  color: #ef0000;
  flex: none;
  margin-left: auto;
}

.payment_3 {
  align-items: flex-end;
  display: flex;
}

.payment_method {
  height: 16px;
  font-family: RixSinGoPSB;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #3d3d3d;
  flex: none;
}

.payment_line {
  width: 100%;
  height: 1px;
  background-color: #ebebeb;
  margin-top: 24px;
  margin-bottom: 24px;
}

.empty {
  margin-top: 32px;
  justify-content: center;
  align-items: center;
}

.empty_img {
  width: 144px;
  height: 63px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.bg_balloon_face {
  width: 144px;
  height: 63px;
  object-fit: contain;
  margin: auto;
  position: absolute;
}

.face {
  width: 20px;
  height: 17px;
  object-fit: contain;
  margin: auto;
  position: absolute;
  top: 16px;
  left: 16px;
}

.ballon_text {
  margin-top: 12px;
  width: 104px;
  height: 16px;
  font-family: RixSinGoPSB;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  text-align: center;
  color: #b2b2b2;
  margin-left: auto;
  margin-right: auto;
}

.select {
  font-family: RixSinGoPEB;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #141414;

  svg {
    fill: $red;
    margin-left: 6px;
  }
}
