@import '../styles/mixins';
@import '../styles/variables';

.layout {
  @include flex();
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  p {
    margin-top: 12px;
  }
}

.logo {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50px;
  text-align: center;
}
