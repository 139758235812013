@import '../../styles/mixins';
@import '../../styles/variables';

$padding: 12px;
$border-radius: 5px;
$border-width: 3px;

/* 쿠폰, 결제금액 */
.description {
  margin-top: 16px;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: -1px;

  .desktop & {
    margin-top: 20px;
  }

  p {
    padding: 0 15px;
  }
}

.message {
  font-size: 12px;
  margin-top: 14px;
}

/* 쿠폰 선택 모듈 */
.coupon {
  @include flex(space-between);

  border: $border-width solid $red;
  border-radius: $border-radius;
  color: $red;
  font-weight: 700;
  margin: 2px 0;
  padding: 10px $padding;
  width: 100%;

  span {
    flex: 1;
    text-align: left;
  }

  .caret {
    @include flex();
    border-left: 1px solid $red;
    padding: 5px;
    padding-left: 15px;
  }

  svg {
    fill: $red;
  }

  &.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  /* punch */
  $punch-width: 12px;
  position: relative;
  overflow: visible;

  &:before {
    left: -($punch-width - $border-width);
    transform: translate(0, -50%) rotate(45deg);
  }

  &:after {
    right: -($punch-width - $border-width);
    transform: translate(0, -50%) rotate(-135deg);
  }

  &:before,
  &:after {
    position: absolute;
    top: 50%;

    content: '';
    display: block;
    width: $punch-width;
    height: $punch-width;
    border-radius: 50%;
    border: $border-width solid $red;
    border-left-color: transparent;
    border-bottom-color: transparent;
    background: white;
  }
}

.dropdown {
  border: $border-width solid $red;
  border-top: 0;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;

  small {
    font-weight: normal;
    margin-left: 4px;
  }
}

.list {
  padding-top: 8px;
}

.empty {
  @include flex();
  color: $gray;
  height: 180px;
}

/* 차이쿠폰 */
.chaiCouponWrapper {
  text-align: center;
}

.chaiCoupon {
  @include flex();
  display: inline-flex;
  color: $black;
  border: 1px solid $light;
  border-radius: 18px;
  height: 42px;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 20px;
  padding: 0 16px;

  .circle {
    @include flex();
    border: 1px solid $red;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  svg {
    fill: $red;
  }
}
