@import '../styles/mixins';
@import '../styles/variables';

.tax {
  color: $red;
  font-size: 12px;
  font-weight: $eb;
  position: relative;
  width: 89px;
  height: 27px;
  margin-bottom: 9px;

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    padding-bottom: 2px;
  }
}

.content {
  left: 36px;
  right: 36px;
  padding: 0;
}

.footer {
  margin-top: auto;
}

.brand {
  color: #141414;
  font-size: 29px;
  font-weight: $eb;
  letter-spacing: -1px;
  line-height: 1.24;
  text-align: center;

  span {
    color: #8f8f8f;
    font-size: 14px;
  }
}

.hoxy {
  color: #8f8f8f;
  font-size: 12px;
  font-weight: $eb;
  letter-spacing: -1px;
  line-height: 1.24;
  text-align: center;
}

.message {
  @include flex(space-between);
  border-radius: 30px;
  font-size: 16px;
  font-weight: $eb;
  margin-top: 16px;
  text-align: center;

  div {
    margin: auto;
    font-size: 16px;
    color: #ffffff;
    font-weight: $eb;
  }
}

.dim {
  background-color: #ebebeb;
}
