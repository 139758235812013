@import '../../styles/mixins';
@import '../../styles/variables';

.container {
  //margin: -24px;
  //padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

img.main {
  display: flex;
  height: 100%;
  margin: auto;
  object-fit: contain;
}
