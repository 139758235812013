@import '../../styles/mixins';
@import '../../styles/variables';

.centerDiv {
  padding: 0 24px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -100px;
  height: 200px;
  text-align: center;

  h2 {
    margin-bottom: 12px;
  }

  p {
    color: $gray;
  }
}
