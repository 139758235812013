.component {
  font-size: 11px;
}

.log {
  font-size: 11px;
  height: 40px;
  border: 1px solid GAINSBORO;
  border-radius: 3px;
}
