@import '../styles/mixins';
@import '../styles/variables';

.default form {
  margin-top: -26px;
}

.header {
  margin-bottom: 32px;
}

.right {
  @include flex(space-between);
}

.title {
  font-size: 24px;
  font-weight: $h;
  letter-spacing: -1px;
  white-space: pre-line;
}

.description {
  color: $dark;
  font-size: 14px;
  margin-top: 8px;
  white-space: pre-line;
}

/* theme */
.auth {
  .header {
    margin-bottom: 24px;
  }

  .title {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -1px;
  }
}

/* desktop */
.desktop {
  padding-top: 40px;
}

/* form */
.form {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  > header {
    flex: none;
  }

  > section {
    flex: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none !important;
    }
  }

  .footer {
    flex: none;
    padding: 40px 0;
  }
}

/* front */
.front .title {
  font-weight: normal;
  letter-spacing: -1px;
}
