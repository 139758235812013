@import 'variables';
@import './components/buttons';

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center {
  position: fixed;
  top: 50%;
  left: $gutter;
  right: $gutter;
  transform: translate(0, -50%);
}

.desktop {
  width: 320px;
  margin: 0 auto;
}

/* color */
.red {
  color: $red;
}

.brand {
  svg & {
    fill: $red;
  }
}

.text-muted {
  color: $dark;
}

.gray {
  color: $gray;
}

.bg {
  background: $bg;
}

/* text */
.text-center {
  text-align: center;
}

/* gutter */
.p-0 {
  padding: 0;
}

/* forms */
.input {
  caret-color: $red;
  flex: 1;
  font-size: 24px;
  font-weight: 400;
  text-align: left;

  &::placeholder {
    color: $light;
  }
}

.input-secure {
  -webkit-text-security: disc;
}

.select {
  font-size: 16px;
}
