@import '../styles/variables';

$size: 18px;

.Input {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 180px;
  height: $size;
}

.Disc {
  background: fade-out($light, 0.2);
  height: $size;
  display: inline-block;

  &.even {
    border-radius: 50%;
    width: $size;

    &.active {
      background: $red;
    }
  }

  &.odd {
    transform: skew(20deg);
    width: 9px;

    &.active {
      background: $black;
    }
  }
}
