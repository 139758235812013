@import '../styles/mixins';
@import '../styles/variables';

.container {
  position: relative;

  svg {
    transform: rotate(-90deg);
  }
}

.text {
  @include flex();
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  color: #707070;
  font-size: 11px;
  font-weight: 500;
}

.track {
  stroke: $light;
}

.progress {
  stroke: $red;
  transition: stroke-dashoffset 1s linear;
}
