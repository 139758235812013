@import '../styles/mixins';
@import '../styles/variables';

.item {
  @include flex(flex-start);
  width: 100%;
  height: 20px;
  padding: 0 20px;
  font-size: 13px;
  white-space: nowrap;
  min-width: 100px;

  svg + span {
    display: inline-block;
    margin-left: 2px;
  }

  span {
    flex: 1;
    text-align: left;
  }
}

.item:hover {
  background: $red;
  color: white;

  svg {
    fill: white;
  }
}

.active {
  padding-left: 0;
}
