@import '../styles/variables';

.compoment {
  position: relative;
}

.actions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0;
  max-height: 25vh;
  z-index: 1;

  background: white;
  font-size: 14px;
}

.item {
  padding: 8px 20px;
  font-weight: 500;

  &:hover:not(:disabled) {
    color: $red;
  }
}
