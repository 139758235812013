@import '../../../styles/mixins';
@import '../../../styles/variables';

.event {
  margin: -24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.img_area {
  width: 100%;
}

.btn_history {
  width: 100%;
  height: 60px;
  background-image: url('../../../images/btn_autoplus_history.png');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  object-fit: contain;
}

.box {
  width: 100%;
  height: 60px;
  background-color: #b2b2b2;
  display: table;
  position: fixed;
  bottom: 0px;
}

.box > span {
  width: 88px;
  height: 16px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  text-align: center;
  color: #ffffff;
  vertical-align: middle;
  display: table-cell;
}
