@import '../../styles/variables';

.center {
  display: flex;
  width: 100%;
  height: 100%;
}

.title {
  margin: auto;
  text-align: center;

  font-size: 24px;
  letter-spacing: -1px;
  color: $black;
}

.content {
  margin: auto;
  margin-top: 10px;
  text-align: center;

  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: $dark;
}

.bottom {
  button {
    position: absolute;
    width: 90%;
    bottom: 5%;
    left: 0;
    right: 0;
    margin: auto;
  }
}
