@font-face {
  font-family: 'ChaiGothic';
  src: url(styles/ChaiGothicM.otf) format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'ChaiGothic';
  src: url(styles/ChaiGothicSB.otf) format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'ChaiGothic';
  src: url(styles/ChaiGothicEB.otf) format('opentype');
  font-weight: 800;
}

@font-face {
  font-family: 'ChaiGothic';
  src: url(styles/ChaiGothicH.otf) format('opentype');
  font-weight: 900;
}

@import './styles/reboot';
@import './styles/components';
