@import '../../styles/mixins';
@import '../../styles/variables';

.section {
  margin-bottom: 48px;
}

.title {
  &:after {
    @include border();
  }

  color: $dark;
  font-size: 12px;
  font-weight: $m;
  padding-bottom: 2px;
}

.dl {
  @include flex(space-between, flex-start);
  flex-wrap: wrap;

  width: 100%;
  color: $black-lightest;
  font-size: 14px;
  font-weight: $sb;

  dt,
  dd {
    margin-top: 16px;
  }

  dt {
    width: 40%;
  }

  dd {
    width: 60%;
    text-align: right;
    word-break: break-all;
  }
}

.gubun {
  border-top: 1px dashed $black;
  margin-top: 16px;
}

.total {
  border-top: 1px dashed $black;
  margin-top: 16px;
  font-weight: $eb;
}

.help {
  font-size: 14px;
  margin-top: 12px;
}

.info {
  color: $dark;
  font-size: 12px;

  p {
    margin-bottom: 12px;
  }
}
