@import '../../styles/variables';

.description {
  margin-top: 75px;

  font-size: 14px;
  font-weight: normal;
  line-height: normal;

  img {
    margin-top: 15px;
  }
}

.flex {
  display: flex;
  margin-top: 15px;

  font-size: 17px;
  font-weight: normal;

  p {
    flex: 60%;
    line-height: 50px;
    height: 50px;
    padding: 0 10px;
  }

  input {
    flex: 60%;
    width: 50%;
    height: 50px;
    margin-right: 15px;
  }

  button {
    flex: 40%;
    padding: 15px 0;
    height: 50px;
  }
}

.input {
  border-bottom: 1px solid $light;

  flex: 1 1;
  font-size: 24px;
  font-weight: 400;
  text-align: left;

  &::placeholder {
    color: $light;
  }

  &:focus {
    border-bottom: 1px solid $red;
  }
}
