@import '../styles/variables';

.article {
  padding: 13px 0;

  > header {
    border-bottom: 2px solid $black;
    padding-bottom: 24px;
    margin-bottom: 40px;

    > h1 {
      font-size: 18px;
      font-weight: $eb;
    }

    > p {
      font-size: 12px;
      margin-top: 8px;
      margin-bottom: 0;
    }
  }

  > section {
    color: $dark;
    font-size: 14px;
    word-break: initial;
    white-space: pre-line;
  }

  h1,
  h2,
  h3 {
    font-size: inherit;
    font-weight: inherit;
  }

  dl {
    dt {
      color: $black;
      font-weight: 700;
    }

    dd {
      margin-bottom: 1em;
    }
  }

  p {
    margin-bottom: 1em;
  }
}
