@import '../../styles/mixins';

.agreement {
  @include flex(flex-start);
  font-size: 14px;
}

.check {
  margin-right: 6px;
}
