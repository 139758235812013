@import '../styles/mixins';
@import '../styles/variables';

$size: 24px;

.input {
  @include flex();

  background: $light;
  border-radius: 50%;
  width: $size;
  height: $size;

  svg {
    fill: $white;
  }
}

.checked {
  background: $red;
}
