@import '../../styles/mixins';
@import '../../styles/variables';

.article {
  border: 1px solid $light;
  margin-bottom: 10px;
  padding: 24px;
  text-align: left;
  width: 100%;
  overflow: hidden;

  section {
    @include flex(space-between, flex-start);
  }

  h1 {
    font-size: 14px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p {
    font-size: 12px;
  }

  ul {
    @include flex(flex-start);
    color: $dark;
    font-size: 12px;
  }

  li {
    margin-right: 10px;
  }

  a {
    border: 1px solid $light;
    border-radius: 2px;
    padding: 2px 5px;
    font-size: 12px;
  }
}

.form {
  @include flex(flex-start);
  margin-top: 10px !important;
  font-size: 12px;
  overflow: hidden;

  input,
  button {
    border: 1px solid $light;
    padding: 2px 5px;
  }

  input {
    flex: 1;
    min-width: 0;
    border-right: 0;
  }

  button {
    flex: none;
    width: 80px;
  }

  .long {
    flex: 2;
  }
}
