@import '../styles/mixins';
@import '../styles/variables';

.footer {
  @include flex(flex-end);
  padding: 20px 0;
}

.button {
  color: $red;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -1px;
  margin-right: 8px;

  &:disabled {
    color: $light;
  }
}
