@import '../styles/variables';
@import '../styles/mixins';

.backdrop {
  @include flex();
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: (1000 + 1);
}

.white {
  background: fade-out($white, 0.5);
}

.box {
  $size: 36px;
  @include flex();
  background: $red;
  width: $size;
  height: $size;

  svg {
    fill: $white;
  }
}

.flip {
  animation-name: flip;
  animation-direction: alternate;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  backface-visibility: visible;
}

@keyframes flip {
  from {
    transform: rotate3d(0, 1, 0, 0);
  }

  to {
    transform: rotate3d(0, 1, 0, 180deg);
  }
}
